import React, { createContext, useState } from "react";
import { useMediaQuery } from "react-responsive";
import steps from "../Common/steps";
import { strings } from "../Common/String";

const AppContext = createContext(null);

let user1 = JSON.parse(sessionStorage.getItem("acumen.user"));
const AppContextProvider = ({ props, children }) => {
  let [state, setState] = useState({
    isAuthenticated: false,
    steps: steps,
    isLoading: false,
    isAlert: false,
    alertDialog: {},
    currentStep: 0,
    openDialog: false,
  });
  const [user, setUserObj] = useState(user1);
  const [hasChanges, setHasChanges] = useState(false);
  const setUser = (user) => {
    setUserObj(user);
    sessionStorage.setItem("acumen.user", JSON.stringify(user));
  };
  const userHasAuthenticated = (authenticated) => {
    setState((prevState) => ({
      ...prevState,
      isAuthenticated: authenticated,
    }));
  };
  const setCurrentStep = (step) => {
    setState((prevState) => ({
      ...prevState,
      currentStep: step,
    }));
  };
  const setToken = (token) => {
    sessionStorage.setItem("acumen.session", token);
  };
  const setSteps = (steps = []) => {
    setState((prevState) => ({
      ...prevState,
      steps: steps,
    }));
  };
  const setLoading = (isLoading) => {
    setState((prevState) => ({
      ...prevState,
      isLoading,
    }));
  };

  const closeConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      isAlert: false,
      alertDialog: {},
    }));
  };
  const showConfirmDialog = (
    message,
    title = undefined,
    confirmButtonAction = undefined,
    confirmButtonText = strings.ok_title,
    cancelButtonText = strings.cancel_title,
    cancelButtonAction = closeConfirmDialog
  ) => {
    setState((prevState) => ({
      ...prevState,
      alertDialog: {
        message,
        title,
        confirmButtonText,
        cancelButtonText,
        confirmButtonAction,
        cancelButtonAction,
      },
      isAlert: true,
    }));
  };

  const clearSession = () => {
    setUser(null);
    sessionStorage.removeItem("acumen.session");
    sessionStorage.removeItem("acumen.user");
  };

  const mobileView = useMediaQuery({ query: "(max-width: 1224px)" });
  return (
    <AppContext.Provider
      value={{
        user: user,
        isLoading: state.isLoading,
        isMobileView: mobileView,
        isAlert: state.isAlert,
        alertDialog: state.alertDialog,
        token: sessionStorage.getItem("acumen.session"),
        steps: state.steps,
        currentStep: state.currentStep,
        isAuthenticated: state.isAuthenticated,
        userHasAuthenticated,
        setUser,
        setLoading,
        setToken,
        setSteps,
        clearSession,
        setCurrentStep,
        closeConfirmDialog,
        showConfirmDialog,
        hasChanges,
        setHasChanges,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
