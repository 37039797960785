import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../Context/AppContext";
import Button from "../Component/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import { strings } from "../Common/String";
import { LeadService } from "../Services/LeadService";
import useScript from "../useScript";
import { PATH } from "../Common/Path";
import queryString from "query-string";
import { useHistory } from "react-router";
import { getCurrentStep, getPath, linkPreventCaching } from "../Common/utils";

const InPerson = (props) => {
  useScript("https://app.digio.in/sdk/v9/digio.js");
  let digio = null;
  const {
    steps,
    setSteps,
    setLoading,
    currentStep,
    setCurrentStep,
    setHasChanges,
  } = useContext(AppContext);
  const [apiMessage, setApiMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isPhotoEnabled, setPhotoEnabled] = useState(false);
  const [isVideoEnabled, setVideoEnabled] = useState(false);
  const [photoButton, setPhotoButton] = useState(strings.takeselfi);
  const [videoButton, setVideoButton] = useState(strings.startvideo);
  const [videoURL, setVideoURL] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    setApiMessage("");
    LeadService.getImageOrVideo()
      .then((data) => {
        setCurrentStep(getCurrentStep(data.current_step));
        if (data.success && data.ipv) {
          setPhotoEnabled(data.ipv.take_photo);
          setVideoEnabled(data.ipv.take_video);
          setVideoURL(linkPreventCaching(data.ipv.video_url));
          setPhotoURL(linkPreventCaching(data.ipv.photo_url));
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));

    if (props.location.search) {
      let qString = queryString.parse(props.location.search);
      const status = qString.status || null;
      let requestId = null;
      if (status && status === "success") {
        requestId = qString.digio_doc_id;
      }
      if (requestId) {
        setLoading(true);
        let body = {
          status: "success",
          request_id: requestId,
        };
        if (
          sessionStorage.getItem("acumen.ipv.type") !== null &&
          sessionStorage.getItem("acumen.ipv.type") === "photo"
        ) {
          updatePhotoDetails(body);
        } else {
          updateVideoDetails(body);
        }
      }
    }
  }, []);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);

  useEffect(() => {
    if (videoURL && videoURL.length > 0) {
      setVideoButton(strings.re_recordvideo);
    } else {
      setVideoButton(strings.startvideo);
    }
  }, [videoURL]);

  useEffect(() => {
    if (photoURL && photoURL.length > 0) {
      setPhotoButton(strings.re_takeselfi);
    } else {
      setPhotoButton(strings.takeselfi);
    }
  }, [photoURL]);

  /**
   * next button
   */
  const nextClick = () => {
    setApiMessage("");
    if (canGoNext()) {
      setLoading(true);
      setApiMessage("");
      LeadService.updateIPVStep()
        .then((data) => {
          if (data.success) {
            history.push(getPath(data.next_step));
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      setApiMessage(strings.finish_ipv_error);
    }
  };

  const canGoNext = () => {
    let canGo = true;
    if (isVideoEnabled && !videoURL) {
      canGo = false;
    }
    if (isPhotoEnabled && !photoURL) {
      canGo = false;
    }
    return canGo;
  };

  /**
   * previous button
   */
  const previousClick = () => {
    history.push(PATH.PAYMENT_PAGE);
  };

  /**
   * onclick video
   */
  const onVideoClick = () => {
    setLoading(true);
    setApiMessage("");
    LeadService.createVideoRequest()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log("error occurred in process");
              }
              updateVideoDetails(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            window.open(
              `${data.redirect_url}&redirect_url=${window.location.protocol + "//" + window.location.host
              }${PATH.INPERSON_PAGE}`,
              "_self"
            );
          }
        } else {
          if (data.digio_error) {
            setApiMessage(strings.retry_error_message);
          } else {
            setApiMessage(data.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const updateVideoDetails = (response) => {
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    setApiMessage("");
    LeadService.updateVideoDetails(body)
      .then((data) => {
        if (data.success) {
          setVideoURL(linkPreventCaching(data.video_url));
          if (data.photo_url) {
            setPhotoURL(linkPreventCaching(data.photo_url));
          }
        } else {
          if (data.digio_error) {
            setApiMessage(strings.retry_error_message);
          } else {
            setApiMessage(data.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const updatePhotoDetails = (response) => {
    sessionStorage.removeItem("acumen.ipv.type");
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    setApiMessage("");
    LeadService.updatePhotoDetails(body)
      .then((data) => {
        if (data.success) {
          if (data.photo_url) {
            setPhotoURL(linkPreventCaching(data.photo_url));
          }
          if (data.video_url) {
            setVideoURL(linkPreventCaching(data.video_url));
          }
        } else {
          if (data.digio_error) {
            setApiMessage(strings.retry_error_message);
          } else {
            setApiMessage(data.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * onclick Photo
   */
  const onPhotoClick = () => {
    setLoading(true);
    setApiMessage("");
    LeadService.createPhotoRequest()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log(strings.processerror);
              }
              updatePhotoDetails(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            sessionStorage.setItem("acumen.ipv.type", "photo");
            window.open(
              `${data.redirect_url}&redirect_url=${window.location.protocol + "//" + window.location.host
              }${PATH.INPERSON_PAGE}`,
              "_self"
            );
          }
        } else {
          if (data.digio_error) {
            setApiMessage(strings.retry_error_message);
          } else {
            setApiMessage(data.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const sendLink = () => {
    setLoading(true);
    LeadService.resumeApplicationLink()
      .then((data) => {
        if (data.success) {
          setSuccessMessage(data.message);
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />
            <div className="col-md-9 pl-3 pt-55 pb-5 pr-3 pl-md-5 pr-md-5 ipv-section">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="pb-2">{strings.inpersonhead}</h5>
                  <p className="sub-head d-none">{strings.inpersonsubhead}</p>
                </div>
                <div className="col-md-12 pt-4 pb-3 d-none">
                  <h6 className=" d-none">{strings.yourotp}</h6>
                </div>
                {isVideoEnabled && (
                  <div className="col-md-8 mb-3">
                    <div className="row camerta">
                      <div className="col-md-4 ipvImage">
                        {videoURL ? (
                          <video src={videoURL} controls></video>
                        ) : (
                          <img
                            src={process.env.PUBLIC_URL + "/img/video2.png"}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="col-md-8 text-justify">
                        <div>
                          <h5>{strings.recordvideo}</h5>
                          <p>{strings.recordvideodesc} </p>
                          <a
                            className="e-sign "
                            onClick={onVideoClick}
                            style={{ textDecoration: "none" }}
                            href="# "
                          >
                            <i
                              className="fa fa-video-camera"
                              aria-hidden="true"
                            />{" "}
                            <span className="paddingleft">{videoButton}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-6 text-center mb-3"></div>
                {isPhotoEnabled && (
                  <div className="col-md-8">
                    <div className="row camerta">
                      <div className="col-md-4 ipvImage">
                        <img
                          src={
                            photoURL ||
                            process.env.PUBLIC_URL + "/img/photo4.png"
                          }
                          alt=""
                        />
                      </div>
                      <div className="col-md-8 text-justify">
                        <div>
                          <h5>{strings.photohead} </h5>
                          <p>{strings.photosubhead}</p>
                          <a
                            className="courier "
                            onClick={onPhotoClick}
                            style={{ textDecoration: "none" }}
                            href="# "
                          >
                            <i className="fa fa-camera" aria-hidden="true" />
                            <span className="paddingleft"> {photoButton}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="pt-4">
                  <p>
                    <b>
                      {strings.sms_note}
                      <span className="normal-link" onClick={sendLink}>
                        here
                      </span>
                    </b>
                  </p>
                  <p>
                    <b>
                      {strings.tips}
                    </b>
                  </p>
                  <p>
                    <b>
                      {strings.ios}
                    </b>
                  </p>

                  <div className="text-success text-center">
                    <label id="txtsuccess" name="txtsuccess">
                      {successMessage}
                    </label>
                  </div>
                </div>
                <div className="col-md-12 pt-2">
                  <div className="row mh-42">
                    <div className="col-md-12 pt-2">
                      <div className="text-danger text-center api-errormessage">
                        <label id="txterror" name="txterror">
                          {apiMessage}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pt-4">
                      <Button
                        classes="inp-back"
                        label={strings.previous}
                        click={previousClick}
                      />
                    </div>
                    <div className="col-6 pt-3 text-right">
                      <Button
                        classes="inp"
                        classes1="buttonmystyle"
                        label={strings.nextbtn}
                        click={nextClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default InPerson;
