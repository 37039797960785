import React, { useEffect, useState, useRef, useContext } from "react";
import { useIdleTimer } from "react-idle-timer/dist/index.legacy.cjs.js";
import { addSeconds, differenceInSeconds } from "date-fns";
import { useHistory } from "react-router";
import { PATH } from "../Common/Path";
import AlertBox from "./AlertBox";
import { strings } from "../Common/String";
import { AppContext } from "../Context/AppContext";

const tenMinutes = 60000 * 10;
const timeoutSeconds = 15;

const CheckUserInactivity = ({ timeout = tenMinutes }) => {
  const [open, setOpen] = useState(false);
  const { clearSession } = useContext(AppContext);
  const history = useHistory();
  const [timeoutTimestamp, setTimeoutTimestamp] = useState(new Date());
  const [secondsRemaining, setSecondsRemaining] = useState(timeoutSeconds);

  const timer = useIdleTimer({
    timeout,
    debounce: 1000,
    crossTab: true,
  });

  useInterval(
    () => {
      const secondsRemaining = differenceInSeconds(
        timeoutTimestamp,
        new Date()
      );
      if (secondsRemaining <= 0) {
        clearSession();
        history.push(PATH.MOBILE_PAGE);
      } else {
        setSecondsRemaining(secondsRemaining);
      }
    },
    open ? 1000 : null
  );

  useInterval(() => {
    if (timer.isIdle()) {
      handleIsIdle();
    } else if (!open) {
      setTimeoutTimestamp(() => addSeconds(new Date(), 17));
      setSecondsRemaining(differenceInSeconds(timeoutTimestamp, new Date()));
    }
  }, 1000);

  const handleIsIdle = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleReset = () => {
    setOpen(false);
    timer.reset();
  };

  return (
    <div className="loaderContainer">
      <AlertBox
        show={open}
        className="w-10"
        title={"Inactivity Detected"}
        confirmButtonText={strings.stay_logged_in}
        cancelButtonAction={() => handleReset()}
      >
        <div className="contents-area text-left">
          {strings.inactivity_message.replace(
            "{{seconds}}",
            secondsRemaining <= 0 ? 0 : secondsRemaining
          )}
        </div>
      </AlertBox>
    </div>
  );
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay && delay !== 0) return;
    const id = setInterval(() => savedCallback.current(), delay);
    return () => clearInterval(id);
  }, [delay]);
};

export { CheckUserInactivity };
