import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Context/AppContext";
import Button from "../../Component/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import { strings } from "../../Common/String";
import { LeadService } from "../../Services/LeadService";
import useScript from "../../useScript";
import { PATH } from "../../Common/Path";
import queryString from "query-string";
import { useHistory } from "react-router";
import { getPath, linkPreventCaching } from "../../Common/utils";
import LayoutWrapper from "../../Common/LayoutWrapper";

const Ipv = (props) => {
  useScript("https://app.digio.in/sdk/v9/digio.js");
  let digio = null;
  const {
    setLoading,
    setHasChanges,
    showConfirmDialog,
    closeConfirmDialog,
    hasChanges,
  } = useContext(AppContext);
  const [apiMessage, setApiMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isPhotoEnabled, setPhotoEnabled] = useState(false);
  const [isVideoEnabled, setVideoEnabled] = useState(false);
  const [photoButton, setPhotoButton] = useState(strings.takeselfi);
  const [videoButton, setVideoButton] = useState(strings.startvideo);
  const [videoURL, setVideoURL] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [step, setStep] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    setApiMessage("");
    Promise.all([
      LeadService.rekycGetIpvDetails(),
      LeadService.rekycGetStepDetails("ReKYCIPV"),
    ])
      .then((data) => {
        if (data[0].success && data[0].ipv) {
          setPhotoEnabled(data[0].ipv.take_photo);
          setVideoEnabled(data[0].ipv.take_video);
          setVideoURL(linkPreventCaching(data[0].ipv.video_url));
          setPhotoURL(linkPreventCaching(data[0].ipv.photo_url));
        } else {
          console.error(data.error);
        }
        if (data[1].success) {
          setStep(data[1].previous_step);
        } else {
          setStep(false);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));

    if (props.location.search) {
      let qString = queryString.parse(props.location.search);
      const status = qString.status || null;
      let requestId = null;
      if (status && status === "success") {
        requestId = qString.digio_doc_id;
      }
      if (requestId) {
        setLoading(true);
        let body = {
          status: "success",
          request_id: requestId,
        };
        if (
          sessionStorage.getItem("acumen.ipv.type") !== null &&
          sessionStorage.getItem("acumen.ipv.type") === "photo"
        ) {
          updatePhotoDetails(body);
        } else {
          updateVideoDetails(body);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (videoURL && videoURL.length > 0) {
      setVideoButton(strings.re_recordvideo);
    } else {
      setVideoButton(strings.startvideo);
    }
  }, [videoURL]);

  useEffect(() => {
    if (photoURL && photoURL.length > 0) {
      setPhotoButton(strings.re_takeselfi);
    } else {
      setPhotoButton(strings.takeselfi);
    }
  }, [photoURL]);

  const previousClick = () => {
    if (hasChanges) {
      showConfirmDialog(strings.confirm_msg, undefined, () => {
        closeConfirmDialog();
        history.push(getPath(step));
      });
    } else {
      history.push(getPath(step));
    }
  };

  const nextClick = () => {
    setApiMessage("");
    if (canGoNext()) {
      setLoading(true);
      setApiMessage("");
      LeadService.rekycUpdateIPVDetails()
        .then((data) => {
          if (data.success) {
            history.push(getPath(data.next_step));
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      setApiMessage(strings.finish_ipv_error);
    }
  };

  const canGoNext = () => {
    let canGo = true;
    if (isVideoEnabled && !videoURL) {
      canGo = false;
    }
    if (isPhotoEnabled && !photoURL) {
      canGo = false;
    }
    return canGo;
  };

  const onVideoClick = () => {
    setLoading(true);
    setApiMessage("");
    LeadService.rekycVideoRequest()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log("error occurred in process");
              }
              updateVideoDetails(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            window.open(
              `${data.redirect_url}&redirect_url=${window.location.protocol + "//" + window.location.host
              }${PATH.REKYC_IPV_PAGE}`,
              "_self"
            );
          }
        } else {
          if (data.digio_error) {
            setApiMessage(strings.retry_error_message);
          } else {
            setApiMessage(data.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const updateVideoDetails = (response) => {
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    setApiMessage("");
    LeadService.rekycUpdateVideoDetails(body)
      .then((data) => {
        if (data.success) {
          setVideoURL(linkPreventCaching(data.video_url));
          if (data.photo_url) {
            setPhotoURL(linkPreventCaching(data.photo_url));
          }
        } else {
          if (data.digio_error) {
            setApiMessage(strings.retry_error_message);
          } else {
            setApiMessage(data.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const updatePhotoDetails = (response) => {
    sessionStorage.removeItem("acumen.ipv.type");
    setLoading(true);
    let body = {
      status: "success",
      request_id: response.digio_doc_id,
    };
    setApiMessage("");
    LeadService.rekycUpdatePhotoDetails(body)
      .then((data) => {
        if (data.success) {
          if (data.photo_url) {
            setPhotoURL(linkPreventCaching(data.photo_url));
          }
          if (data.video_url) {
            setVideoURL(linkPreventCaching(data.video_url));
          }
        } else {
          if (data.digio_error) {
            setApiMessage(strings.retry_error_message);
          } else {
            setApiMessage(data.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const onPhotoClick = () => {
    setLoading(true);
    setApiMessage("");
    LeadService.rekycPhotoRequest()
      .then((data) => {
        if (data.success) {
          if (data.use_sdk) {
            let options = data.sdk.options;
            options.callback = function (response) {
              if (response.hasOwnProperty("error_code")) {
                return console.log(strings.processerror);
              }
              updatePhotoDetails(response);
            };
            digio = new window.Digio(options);
            digio.init();
            digio.submit(
              data.sdk.requestId,
              data.sdk.identifier,
              data.sdk.token_id
            );
          } else {
            sessionStorage.setItem("acumen.ipv.type", "photo");
            window.open(
              `${data.redirect_url}&redirect_url=${window.location.protocol + "//" + window.location.host
              }${PATH.REKYC_IPV_PAGE}`,
              "_self"
            );
          }
        } else {
          if (data.digio_error) {
            setApiMessage(strings.retry_error_message);
          } else {
            setApiMessage(data.error);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const sendLink = () => {
    setLoading(true);
    LeadService.rekycResumeApplicationLink()
      .then((data) => {
        if (data.success) {
          setSuccessMessage(data.message);
          setTimeout(() => {
            setSuccessMessage("");
          }, 3000);
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <LayoutWrapper>
      <div className="summarydiv">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 marg">
              <div className="forms">
                <div className="row pl-5 pb-2 ipv-pd">
                  <div className="col-md-12">
                    <h4 className="head5 pb-3">{strings.inpersonhead}</h4>
                    <p className="sub-head d-none">{strings.inpersonsubhead}</p>
                  </div>
                  <div className="col-md-12 pt-4 pb-3 d-none">
                    <h6 className=" d-none">{strings.yourotp}</h6>
                  </div>
                  {isVideoEnabled && (
                    <div className="col-md-11 pl-4 mb-3">
                      <div className="row camerta w-414">
                        <div className="col-md-4 ipvImage h-120">
                          {videoURL ? (
                            <video src={videoURL} controls></video>
                          ) : (
                            <img
                              src={process.env.PUBLIC_URL + "/img/video2.png"}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="col-md-8">
                          <div>
                            <h5 className="head5 pb-1">
                              {strings.recordvideo}
                            </h5>
                            <p className="text_left">
                              {strings.recordvideodesc}{" "}
                            </p>
                            <a
                              className="e-sign "
                              onClick={onVideoClick}
                              style={{ textDecoration: "none" }}
                              href="# "
                            >
                              <i
                                className="fa fa-video-camera"
                                aria-hidden="true"
                              />{" "}
                              <span className="paddingleft">{videoButton}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6 text-center mb-3"></div>
                  {isPhotoEnabled && (
                    <div className="col-md-11 pl-4">
                      <div className="row camerta w-414">
                        <div className="col-md-4 ipvImage h-120">
                          <img
                            src={
                              photoURL ||
                              process.env.PUBLIC_URL + "/img/photo4.png"
                            }
                            alt=""
                          />
                        </div>
                        <div className="col-md-8">
                          <div>
                            <h5 className="head5 pb-1">{strings.photohead} </h5>
                            <p className="text_left">{strings.photosubhead}</p>
                            <a
                              className="courier "
                              onClick={onPhotoClick}
                              style={{ textDecoration: "none" }}
                              href="# "
                            >
                              <i className="fa fa-camera" aria-hidden="true" />
                              <span className="paddingleft">
                                {" "}
                                {photoButton}
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="pt-4 pr-5">
                    <p className="pl-2 ytlink">
                      <b>
                        {strings.rekyc_tips}
                        <a
                          href="https://youtube.com/shorts/5o1BLpQY4As?feature=share"
                          target="_blank"
                          className="normal-link"
                          rel="noreferrer"
                        >
                          here.
                        </a>
                      </b>
                    </p>
                    <p className="pl-2 ytlink">
                      <b>
                        {strings.rekyc_sms}
                        <span className="normal-link" onClick={sendLink}>
                          here.
                        </span>
                      </b>
                    </p>
                    <div className="text-success text-center">
                      <label id="txtsuccess" name="txtsuccess">
                        {successMessage}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12 pt-2">
                    <div className="row mh-42">
                      <div className="col-md-12 pt-2">
                        <div className="text-danger text-center api-errormessage">
                          <label id="txterror" name="txterror">
                            {apiMessage}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-6">
                        {step && (
                          <Button
                            classes="inp-back lp-10"
                            label={strings.previous}
                            click={previousClick}
                          />
                        )}
                      </div>
                      <div className="col-6 text-right">
                        <Button
                          classes="inp ipvbtn"
                          label={strings.nextbtn}
                          click={nextClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default Ipv;
