import { PATH } from "../Common/Path";

export const RestService = {
    get: async function (url, headers) {
        headers["Content-Type"] = "application/json";
        headers["touch-token"] = sessionStorage.getItem('acumen.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
        // if(url.includes('?')){
        //     url= `${url}&user_type=${sessionStorage.getItem('acumen.user_type')}&user_code=${sessionStorage.getItem('acumen.user_code')}`
        // } else {
        //     url= `${url}?user_type=${sessionStorage.getItem('acumen.user_type')}&user_code=${sessionStorage.getItem('acumen.user_code')}`
        // }
        return fetch(url, {
            method: "GET",
            headers: headers
        })
            .then(response => {
                if (response.status === 401) {
                    sessionStorage.removeItem('acumen.user');
                    sessionStorage.removeItem('acumen.session');
                    window.open(PATH.MOBILE_PAGE, "_self");
                    const error = (response && response.message) || response.statusText;
                    return Promise.reject(error);
                }

                return response;
            });
    },
    delete: async function (url, headers) {
        headers["Content-Type"] = "application/json";
        // if(url.includes('?')){
        //     url= `${url}&user_type=${sessionStorage.getItem('acumen.user_type')}&user_code=${sessionStorage.getItem('acumen.user_code')}`
        // } else {
        //     url= `${url}?user_type=${sessionStorage.getItem('acumen.user_type')}&user_code=${sessionStorage.getItem('acumen.user_code')}`
        // }
        headers["touch-token"] = sessionStorage.getItem('acumen.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
        return fetch(url, {
            method: "DELETE",
            headers: headers
        });
    },
    post: async function (url, headers, body) {
        headers["Content-Type"] = "application/json";
        // if(url.includes('?')){
        //     url= `${url}&user_type=${sessionStorage.getItem('acumen.user_type')}&user_code=${sessionStorage.getItem('acumen.user_code')}`
        // } else {
        //     url= `${url}?user_type=${sessionStorage.getItem('acumen.user_type')}&user_code=${sessionStorage.getItem('acumen.user_code')}`
        // }
        headers["touch-token"] = sessionStorage.getItem('acumen.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
        return fetch(url, {
            method: "POST",
            headers: headers,
            body: body ? JSON.stringify(body) : null
        })
            .then(response => {
                if (response.status === 401) {
                    sessionStorage.removeItem('acumen.user');
                    sessionStorage.removeItem('acumen.session');
                    window.open(PATH.MOBILE_PAGE, "_self");
                    const error = (response && response.message) || response.statusText;
                    return Promise.reject(error);
                }
                return response;
            });
    },
    postFormData: async function (url, headers, body) {
        headers["enctype"] = "multipart/form-data";
        // if(url.includes('?')){
        //     url= `${url}&user_type=${sessionStorage.getItem('acumen.user_type')}&user_code=${sessionStorage.getItem('acumen.user_code')}`
        // } else {
        //     url= `${url}?user_type=${sessionStorage.getItem('acumen.user_type')}&user_code=${sessionStorage.getItem('acumen.user_code')}`
        // }
        headers["touch-token"] = sessionStorage.getItem('acumen.session');
        headers["app-version"] = global.appVersion;
        headers["platform"] = 'web';
        return fetch(url, {
            method: "POST",
            headers: headers,
            body: body
        })
            .then(response => {
                if (response.status === 401) {
                    sessionStorage.removeItem('acumen.user');
                    sessionStorage.removeItem('acumen.session');
                    window.open(PATH.MOBILE_PAGE, "_self");
                    const error = (response && response.message) || response.statusText;
                    return Promise.reject(error);
                }
                return response;
            });
    }
};
